var cusOfferDetailPage = function(){
    var scope = ".js-cus-offer-detail-page";
    ui.ready(scope,function($page){
        $page.find("[data-name='accept_report']").hide();
        var offerId = $page.find("[name='id']").val();
        //setValuesOnAddresses();
        $page.find("[data-name='cus_tax_id']").trigger("formField:changeVal");
        function scrollToError(){
            if($page.find(".has-error") && $page.find(".has-error").length>0){
                $page.find(".has-error").get(0).scrollIntoView({
                    behavior:"smooth",block:"center"
                });
            }
        }

        $page.find(scope+"__change-status").off().click(function(ev){
            ev.preventDefault();
            ev.stopPropagation();
            var statusId = $(this).data("status");
            var statusText = $(this).text();
            var offerId = $page.find('[name="id"]').val();
            var $button = $(this);

            if(statusId!=1){
                $form = $page.find(".js-detail-page__main-form");
                $page.find("[name='is_required']").val('true');

                $form.checkBindings();
                var isOk = $form.checkRestrictions();
                if(!isOk){
                    $page.find("[name='is_required']").val('');
                    $form.checkBindings();
                    var msg = "Debe rellenar todos los campos obligatorios para pasar al estado: "+statusText;
                    utils.alertEndpointResponse(msg);
                    return false;
                }
            }

            var OfferWorkDoneStatus = 8;
            if(statusId==OfferWorkDoneStatus){
                var resp = gvf.endpointSync("controller\\cp\\cusOfferDetailController::hasWorkReportFiles",[offerId]);
                if(resp.status=="ok" && !resp.hasWorkReportFiles){
                    var msg = "Debe adjuntar archivos en 'Partes de trabajo' para pasar a este estado.";
                    utils.alertEndpointResponse(msg);
                    return false;
                }
            }
            swal({
                title:"¿Cambiar el estado de la oferta?",
                text:"Cambiará a: "+statusText,
                type:"info",
                showCancelButton:true,
                confirmButtonColor:"#00bb00",
                confirmButtonText:"Aceptar",
                cancelButtonText:"Cancelar",
                closeOnConfirm:false,
                closeOnCancel:true,
                showLoaderOnConfirm:true
            },function(isConfirm){
                if(isConfirm){
                    $button.prop("disabled",true);
                    gvf.endpoint("controller\\cp\\cusOfferDetailController::changeOfferStatusById",[statusId,offerId],function(resp){
                        if(resp.status=="ok"){
                            utils.alertEndpointResponse(resp);
                            gvf.delay(1500,function(){
                                window.location.reload();
                            });

                        }else{
                            utils.alertEndpointResponse(resp);
                        }
                    });
                }else{
                    $page.find("[name='is_required']").val('');
                    $form.checkBindings();
                    var isOk = $form.checkRestrictions();
                }
            });
        });

        $page.find(scope+"__success-modal").click(
            function(ev){
                ev.preventDefault();
                detailPage.openModal(
                    "CusOfferQualityControl",
                    null,
                    true,
                    null,
                    null,
                    {
                        initContent:function(modal,data){
                            cusOfferQualityControlDetailPage.initCusOfferQualityControlDetailPage(modal,data)
                        },
                        modalListTemplate:'cp/cus-offer/cus-offer-quality-control-detail-page.twig',
                        title:'Control de calidad',
                        additionalData: {offerId:offerId, modalOffer : "completeOffer"}
                    },
                    null
                );

            }
        );

        $page.find(scope+"__approve-modal").click(
            function(ev){
                ev.preventDefault();
                detailPage.openModal(
                    "CusOfferQualityControl",
                    null,
                    true,
                    null,
                    null,
                    {
                        initContent:function(modal,data){
                            cusOfferQualityControlDetailPage.initCusOfferQualityControlDetailPage(modal,data)
                        },
                        modalListTemplate:'cp/cus-offer/cus-offer-quality-control-detail-page.twig',
                        title:'Aprobación de oferta',
                        additionalData: {offerId:offerId, modalOffer : "approveOffer"}
                    },
                    null
                );

            }
        );

        $page.find(scope+"__reject-requests").off().click(function(ev){

            gvf.endpoint("controller\\cp\\cusOfferDetailController::resetSupplierRequest",[offerId],function(resp){
                utils.alertEndpointResponse(resp);
                gvf.delay(2500,function(){
                    window.location = resp.url;
                });
            })
        });

        $page.find("[data-name='cus_tax_id']").on('formField:changeVal',(function(ev){
            var tax = $page.find("[name='cus_tax_id']");
            var items = $page.find("[data-name='detail_items']");
            items.data("cus_tax_id",tax.val());
        }));

        $page.find("[data-name='customer_id']").on('formField:changeVal',(function(ev){
            var customer_id = $(this).find("[name='customer_id']").val();
            if(customer_id){
                gvf.endpoint("controller\\cp\\cusOfferDetailController::getCusPaymentMethodId",[customer_id],function(resp){
                    if(resp.status=="ok"){
                        $page.find("[data-name='cus_payment_method_id']").formFieldSetVal(resp.payment_id);
                    }
                })
            }else{
                $page.find("[name='cus_payment_method_id']").val("");
            }
        }));

        $page.find(".js-detail-page__main-form").unbind('submit').submit(function(ev){
            ev.preventDefault();
            ev.stopPropagation();
            ev.stopImmediatePropagation();
            var $form = $(this);
            var isOk = $form.checkRestrictions();
            scrollToError();
            if(isOk){
                page.showLoading();
                gvf.endpoint($page.data("controller")+"::submitDetailForm",[$page.data("table-name"),$form.getValues()],function(resp){
                    page.hideLoading();
                    if(resp.status=="ok"){
                        if($page.data("saveCallback")){
                            $page.data("saveCallback")();
                        }else{
                            if(resp.code_type!="edited"){
                                utils.alertEndpointResponse(resp);
                                gvf.delay(2500,function(){
                                    window.location = resp.url;
                                });
                            }else{
                                window.location = resp.url;
                            }
                        }
                    }else if(resp.validation){
                        $form.applyValidationResponse(resp.validation);
                    }else{
                        utils.alertEndpointResponse(resp);
                    }
                });
            }
        });

        $page.find("[data-name='customer_type_id']").on('formField:changeVal',(function(ev){
            var cus_facility = $page.find("[data-name='cus_facility_id']");
            var customer_id_facility = $page.find("[data-name='customer_id_facility']");
            cus_facility.formFieldSetVal("");
            customer_id_facility.formFieldSetVal("");
        }));

        $page.find("[data-name='cus_facility_id']").on("formField:changeVal",(
            function(ev){
                ev.preventDefault();
                var cus_facility_id = $page.find("[name='cus_facility_id']").val();
                gvf.endpoint(
                    "controller\\cp\\cusOfferDetailController::checkContractInForce",
                    [cus_facility_id],
                    function(resp){
                        if(resp.status=="ok"){
                            if(resp.contractInForce==="0"){
                                swal({
                                    title: "Cliente sin contrato en vigor",
                                    text: "Este cliente no tiene contrato en vigor, ¿está seguro?",
                                    type: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Aceptar",
                                    cancelButtonText: "Cancelar",
                                    closeOnConfirm: true,
                                    closeOnCancel: true
                                },function(isConfirm){
                                    if(!isConfirm){
                                        $page.find("[data-name='cus_facility_id']").formFieldSetVal("");
                                    }
                                });
                            }
                        }
                    }
                );
            }
        ));

        $page.find("[data-name='unit_items']").on('formField:changeVal',(function(ev){
            var unit_items = $page.find("[name='unit_items']").prop("checked");
            var offerId = $page.find("[name='id']").val();
            var details = $page.find("[name='detail_items']").val();
            gvf.endpoint("controller\\cp\\cusOfferDetailController::reloadDetailItems",[unit_items,offerId,JSON.parse(details)],function(resp){
                if(resp.status=="ok"){
                    $page.find("[data-name='detail_items']").formFieldSetVal(resp.items);
                }
            })
        }));

        $page.find("[data-name='customer_id_facility']").on('formField:changeVal',(function(ev){
            var cus_contact = $page.find("[data-name='cus_contact_id']");
            cus_contact.formFieldSetVal("");
        }));

        $page.find("[data-name='cus_contact_id']").click(
            function(ev){
                var cus_facility = $page.find("[data-name='cus_facility_id']");
                cus_facility.formFieldSetVal("");

                gvf.delay(3000,function(){});
            }
        );

        $page.find("[data-name='cus_contact_id']").on('formField:changeVal',(function(ev){
            var cus_contact_id = $(this).find("[name='cus_contact_id']").val();
            var cus_facility = $page.find("[data-name='cus_facility_id']");
            if(cus_contact_id){
                cus_facility.formFieldSetVal("99999");
            }
        }));

        $page.find("[data-name='cus_facility_id']").on('formField:changeVal',(function(ev){
            var cus_facility_id = $(this).find("[name='cus_facility_id']").val();
            var customer = $page.find("[data-name='customer_id']");
            var cus_contact = $page.find("[data-name='cus_contact_id']");
            var $customer_type = $page.find("[data-name='customer_type_id']");
            var customer_type_id = $customer_type.children().eq(1).val();
            if(customer_type_id){
                if(cus_facility_id){
                    gvf.endpoint("controller\\cp\\cusOfferDetailController::getCustomerIdByFacilityId",[cus_facility_id],function(resp){
                        if(resp.status=="ok"){
                            customer.formFieldSetVal(resp.customer_id);
                        }else{
                            customer.formFieldSetVal("");
                        }
                    });
                }else{
                    customer.formFieldSetVal("");
                    if(customer_type_id != 2){
                        cus_contact.formFieldSetVal("");
                    }
                }
            }
        }));

        $page.find("[data-name='execution_cp_user_id']").on(
            'formField:changeVal',
            (function(ev){
                ev.preventDefault();
                executionCpUserId = $(this).formFieldVal();

                gvf.endpoint(
                    "controller\\cp\\cusOfferQualityControlDetailController::getDepartmentCpUser",
                    [executionCpUserId],
                    function(resp){
                        if(resp.status=="ok"){
                            $page.find("[data-name=cp_user_department_id]").formFieldSetVal(resp.department_id);
                        }
                    }
                );
            })
        );

        $page.find(scope+"__recovery").click(function(ev){
            var offerId = $page.find("[name='id']").val();
            swal({
                title:"¿Deseas recuperar la oferta actual?",
                text:"Volverá a estar disponible en la sección Ofertas",
                type:"info",
                showCancelButton:true,
                confirmButtonColor:"#00bb00",
                confirmButtonText:"Aceptar",
                cancelButtonText:"Cancelar",
                closeOnConfirm:false,
                closeOnCancel:true,
                showLoaderOnConfirm:true
            },function(isConfirm){
                if(isConfirm){
                    gvf.endpoint("controller\\cp\\cusOfferDetailController::recoverOffer",[offerId],function(resp){
                        if(resp.status=="ok"){
                            utils.alertEndpointResponse(resp);
                            gvf.delay(1500,function(){
                                window.location.reload();
                            });

                        }else{
                            utils.alertEndpointResponse(resp);
                        }
                    });
                }
            });
        });

        $page.find(scope+"__duplicate-offer").click(function(ev){
            var $form = $page.find(".js-detail-page__main-form");
            var isOk = $form.checkRestrictions();
            scrollToError();
            if(isOk || 1==1){
                swal({
                    title:"¿Deseas duplicar esta oferta?",
                    text:"Se creará una nueva oferta con los datos de la actual pero con un nuevo Nº de Oferta y en estado 'Pendiente de realizar'. La oferta actual se conserva intacta.",
                    type:"info",
                    showCancelButton:true,
                    confirmButtonColor:"#00bb00",
                    confirmButtonText:"Aceptar",
                    cancelButtonText:"Cancelar",
                    closeOnConfirm:false,
                    closeOnCancel:true,
                    showLoaderOnConfirm:true
                },function(isConfirm){
                    if(isConfirm){
                        gvf.endpoint("controller\\cp\\cusOfferDetailController::createSimilarOffer",[$form.getValues(),true],function(resp){
                            if(resp.status=="ok"){
                                utils.alertEndpointResponse(resp);
                                gvf.delay(1500,function(){
                                    window.location = resp.url;
                                });
                            }else{
                                utils.alertEndpointResponse(resp);
                            }
                        });
                    }
                });
            }

        });

        $page.find(scope+"__new-version").click(function(ev){
            var $form = $page.find(".js-detail-page__main-form");
            var isOk = $form.checkRestrictions();
            scrollToError();
            if(isOk){
                swal({
                    title:"¿Deseas crear una nueva versión de esta oferta?",
                    text:"Se creará una nueva oferta con los datos de la actual. El Nº de Oferta será el actual seguido de la versión que corresponda. La oferta estará en estado 'Pendiente de realizar'. Quedará relacionada con la versión original y ambas se podrán consultar.",
                    type:"info",
                    showCancelButton:true,
                    confirmButtonColor:"#00bb00",
                    confirmButtonText:"Aceptar",
                    cancelButtonText:"Cancelar",
                    closeOnConfirm:false,
                    closeOnCancel:true,
                    showLoaderOnConfirm:true
                },function(isConfirm){
                    if(isConfirm){
                        gvf.endpoint("controller\\cp\\cusOfferDetailController::createSimilarOffer",[$form.getValues(),false],function(resp){
                            if(resp.status=="ok"){
                                utils.alertEndpointResponse(resp);
                                gvf.delay(1500,function(){
                                    window.location = resp.url;
                                });
                            }else{
                                utils.alertEndpointResponse(resp);
                            }
                        });
                    }
                });
            }

        });
        toggleAcceptReport();
        $page.find("[data-name='spoke_accept']").on('formField:changeVal',(function(ev){
            toggleAcceptReport();
        }));

        $page.find("[data-name='receiver_cus_contact_ids'],[data-name='customer_id'],[data-name='cus_contact_id'],[data-name='customer_id_facility']").on('formField:changeVal',(function(ev){
            setValuesOnAddresses();
        }));

        $page.find("[data-name='select_cus_offer_header']").on('formField:changeVal',(function(ev){
            var entityType = $page.find("[name='header_entity_type']");
            var entityId = $page.find("[name='header_entity_id']");
            var select = $page.find("[name='select_cus_offer_header']");
            entityType.formFieldSetVal(select.children("option:selected").data("entitytype"));
            entityId.formFieldSetVal(select.children("option:selected").data("entityid"));
        }));

        $page.find("[data-name='project']").on('formField:changeVal',(function(ev){
            var projectTaskId = $(this).formFieldVal();
            var task = false;
            $page.find("[data-name='task']").formFieldSetVal();
            gvf.endpoint(
                "controller\\cp\\cusOfferDetailController::getProjectName",
                [projectTaskId],
                function(resp){
                    if(resp.status=="ok"){
                        $page.find("[data-name='task']").attr("data-projectname",resp.project);
                    }
                }
            );

            gvf.endpoint(
                "controller\\cp\\cusOfferDetailController::getDeliveryNotesAndSupOrder",
                [projectTaskId,task],
                function(resp){
                    if(resp.status=="ok"){
                            $page.find("[data-name='delivery_notes']").formFieldSetVal(resp.deliveryNoteIds);

                            $page.find("[data-name='sup_order_ids']").formFieldSetVal(resp.supOrderIds);
                    }
                }
            );
        }));

        $page.find("[data-name='task']").on('formField:changeVal',(function(ev){
            var projectTaskId = $(this).formFieldVal();
            var task = true;
            $page.find("[data-name='delivery_notes']").formFieldSetVal("");
            $page.find("[data-name='sup_order_ids']").formFieldSetVal("");
            gvf.endpoint(
                "controller\\cp\\cusOfferDetailController::getDeliveryNotesAndSupOrder",
                [projectTaskId,task],
                function(resp){
                    if(resp.status=="ok"){
                        if(resp.projectTask != null && resp.deliveryNoteIds.length>2){
                            $page.find("[data-name='delivery_notes']").formFieldSetVal(resp.deliveryNoteIds);
                        }
                        if(resp.projectTask != null && resp.supOrderIds.length>2){
                            $page.find("[data-name='sup_order_ids']").formFieldSetVal(resp.supOrderIds);
                        }
                    }
                }
            );
        }));

        function toggleAcceptReport(){
            var selectValue = $page.find("[name='spoke_accept']").children("option:selected").attr("value");
            if(selectValue == 0){
                $page.find("[data-name='accept_report']").show("fast");
            } else{
                $page.find("[data-name='accept_report']").hide();
            }
        }

        function setValuesOnAddresses(){
            var customer = $page.find("[data-name='customer_id']");
            var customerFacility = $page.find("[data-name='customer_id_facility']");
            var cusContact = $page.find("[data-name='cus_contact_id']");
            var contacts = $page.find("[data-name='receiver_cus_contact_ids']");
            var customerId = customer.formFieldVal();
            var contactsId = contacts.formFieldVal();
            var customerIdFacility = customerFacility.formFieldVal();
            var cusContactId = cusContact.formFieldVal();
            var selectedEntityType = $page.find("[name='header_entity_type']");
            var entityType = selectedEntityType.formFieldVal();
            var selectedEntityId = $page.find("[name='header_entity_id']");
            var entityId = selectedEntityId.formFieldVal();

            gvf.endpoint("controller\\cp\\cusOfferDetailController::relatedHeaders",
                [customerId,customerIdFacility,cusContactId,contactsId],
                function(resp){
                if(resp.status=="ok"){
                    $page.find("[data-name='select_cus_offer_header']").find('option').remove().end();
                    if(resp.addresses){
                        for(var i = 0; i<resp.addresses.length; i++){
                            $page.find("select[name='select_cus_offer_header']").append('<option value="'+resp.addresses[i].value+'" data-entitytype="'+resp.addresses[i].entity_type+'" data-entityid="'+resp.addresses[i].entity_id+'" data-address="'+resp.addresses[i].address+'" data-name="'+resp.addresses[i].name+'">'+resp.addresses[i].text+'</option>');
                        }
                        var selectAdd = $page.find("[data-name='select_cus_offer_header']");
                        if(entityType!=="" && entityId!==""){
                            selectAdd.formFieldSetVal(entityType+"#"+entityId);
                        }else{
                            if(customerId!==""){
                                selectAdd.formFieldSetVal("customer#"+customerId);
                            }
                        }
                    }
                }else{
                    utils.alertEndpointResponse(resp);
                }
            });
        }
        //end ready
    });
}();


